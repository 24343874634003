import React, { Fragment } from "react"
import { graphql } from "gatsby"

/* Import Global Component(s) */
import SEO from "~components/seo"

import Logo from "~components/logo"
import Mailchimp from "~components/mailchimp"
import SocialListItem from "~components/socialListItem"
import SocialList from "~components/socialList"
import Copyright from "~components/copyright"

const LandingPage = ({
  data: {
    sanitySettings: { links, socials },
  },
}) => {
  return (
    <Fragment>
      <SEO title={"Hydromania"} />
      <Logo links={links} />
      <Mailchimp />
      <SocialList>
        {socials.map(social => (
          <SocialListItem
            key={social._key}
            name={social.platform}
            link={social.url}
          />
        ))}
      </SocialList>
      <Copyright />
    </Fragment>
  )
}
export default LandingPage

export const query = graphql`
  query WebsiteSettings {
    sanitySettings(_id: { eq: "settings" }) {
      id
      links {
        url
        title
        _key
      }
      backgroundColor {
        hex
      }
      linksColor {
        hex
      }
      socials {
        url
        platform
        _key
      }
    }
  }
`
