import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

/* Import Global Asset */
import LogoSvgSrc from "~assets/images/kelsey-lu-logo.svg"

/* Import Local Styles */
import "./logo.css"

const Logo = ({ links }) => (
  <div className="logo">
    <img src={LogoSvgSrc} />
    <div className="links">
      {links.map(link => (
        <OutboundLink key={link._key} href={link.url} target="_blank">
          {link.title}
        </OutboundLink>
      ))}
    </div>
  </div>
)

export default Logo
