import React from "react"
import {
  FaSpotify,
  FaApple,
  FaYoutube,
  FaInstagram,
  FaTwitter,
  FaFacebookF,
} from "react-icons/fa"

const SocialIcon = ({ name }) => {
  if (!name) {
    console.error("Name not set for SocialIcon")
    return null
  }
  switch (name) {
    case "spotify":
      return <FaSpotify />
    case "apple":
      return <FaApple />
    case "youtube":
      return <FaYoutube />
    case "instagram":
      return <FaInstagram />
    case "twitter":
      return <FaTwitter />
    case "facebook":
      return <FaFacebookF />
    default:
      return null
  }
}

export default SocialIcon
