import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        lang
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        banner
      }
    }
  }
`

const SEO = ({ title, description, image }) => {
  const { pathname } = useLocation()
  const {
    site,
    site: { siteMetadata },
  } = useStaticQuery(query)

  // Destructure SiteMetadata Properties
  const {
    lang,
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    banner,
  } = siteMetadata

  // Generate SEO Data Object
  const seo = {
    lang: lang,
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
    canonical: pathname ? `${siteUrl}${pathname}` : null,
    image: image || `${siteUrl}/${banner}`,
  }

  // Return React-Helmet
  return (
    <Helmet
      htmlAttributes={{
        lang: seo.lang,
      }}
      title={seo.title}
      titleTemplate={titleTemplate}
      link={
        seo.canonical
          ? [
              {
                rel: "canonical",
                href: seo.canonical,
              },
            ]
          : []
      }
    >
      {/* apple and application tags */}
      <meta name="apple-mobile-web-app-title" content={defaultTitle} />
      <meta name="application-name" content={defaultTitle} />
      {/* Meta Tags */}
      {seo.description && <meta name="description" content={seo.description} />}
      {/* Open Graph Tags */}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta property="og:type" content="website" />
      <meta property="og:image" content={seo.image} />
      <meta property="og:width" content="1200" />
      <meta property="og:height" content="630" />
      {/* Twitter Open Graph Tags */}
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}

export default SEO
