import React from "react"

/* Import Local Styles */
import "./copyright.css"

const Copyright = () => (
  <div className="copyright">
    <p>ALL RIGHTS RESERVED</p>
    <p>© 2020 KELSEY LU HYDROHARMONIA</p>
  </div>
)

export default Copyright
