import React from "react"
import { IconContext } from "react-icons"

/* Import Local Styles */
import "./social-list.css"

const SocialList = ({ children }) => (
  <IconContext.Provider value={{ size: "1.9em" }}>
    <ul className="social-list">{children}</ul>
  </IconContext.Provider>
)

export default SocialList
