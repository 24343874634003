import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import SocialIcon from "~components/socialIcon"

import "./social-list-item.css"

const SocialList = ({ name, link }) => (
  <li className="social-list-item">
    <OutboundLink href={link} target="_blank">
      <SocialIcon name={name} />
    </OutboundLink>
  </li>
)

export default SocialList
